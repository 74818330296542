<template>
  <CHeader with-subheader style="background-color: #4786ff !important;">
    <CHeaderBrand class="mx-auto d-lg-none" to="/"></CHeaderBrand>
    <CHeaderNav class="d-md-down-none mr-auto">
      <CHeaderNavItem class="px-12">
      </CHeaderNavItem>
    </CHeaderNav>
    <CHeaderNav>
      <TheDocsHeaderDropdownAccnt/>
    </CHeaderNav>
  </CHeader>
</template>

<script>
import router from '../router/index'
import TheDocsHeaderDropdownAccnt from './TheDocsHeaderDropdownAccnt'
import account_ws from '../services/account';
import login from '../services/login';

export default {
  name: 'TheDocsHeader',
  components: {
    TheDocsHeaderDropdownAccnt
  },
  data () {
    return { 
      has_partners: 0,
      has_owners: 0
    }
  },
  mounted: async function() {
    
  },
  methods: {
    showAccountModal () {
      this.$refs.newAccountModal.storeModal();
    },
    storeAccount (data) {
      this.$refs.partnersDropdown.refresh();
    },
    logout: function(){
      localStorage.removeItem("account");
      localStorage.removeItem("token");

      router.push("login");
    },
  }
}
</script>
