import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer')
const TheDocsContainer = () => import('@/containers/TheDocsContainer')

//Sections
const Login = () => import('@/pages/application/login/Login')
const NewAccountForm = () => import('@/pages/application/login/NewAccountForm')
const ActivationForm = () => import('@/pages/application/login/ActivationForm')
const ForgetForm = () => import('@/pages/application/login/ForgetForm')
const RecoveryForm = () => import('@/pages/application/login/RecoveryForm')
const Privacy = () => import('@/pages/application/login/Privacy')

const Welcome = () => import('@/pages/application/Welcome')
const MyAccount = () => import('@/pages/application/MyAccount')
const MyNotifications = () => import('@/pages/application/MyNotifications')

const Map = () => import('@/pages/application/Map')
const Roads = () => import('@/pages/application/Roads')
const Capture = () => import('@/pages/application/Capture')
const Demo = () => import('@/pages/application/Demo')
const BotMap = () => import('@/pages/application/BotMap')

const Users = () => import('@/pages/application/Users')
const Roles = () => import('@/pages/application/Roles')
const Collaborators = () => import('@/pages/application/Collaborators')
const BinnacleUsers = () => import('@/pages/application/BinnacleUsers')
const Councils = () => import('@/pages/application/Councils')
const Directions = () => import('@/pages/application/Directions')
const Citizens = () => import('@/pages/application/Citizens')

const Ticket = () => import('@/pages/application/Ticket')
const Tickets = () => import('@/pages/application/Tickets')
const TicketsMap = () => import('@/pages/application/TicketsMap')
const PublicTicket = () => import('@/pages/application/PublicTicket')
const ValidationTicket = () => import('@/pages/application/ValidationTicket')

const BinnacleEvents = () => import('@/pages/application/BinnacleEvents')
const BinnacleMap = () => import('@/pages/application/BinnacleMap')

//Documents Login
const DocsLogin = () => import('@/pages/docs/login/Login')
const DocsHome = () => import('@/pages/docs/Home')

Vue.use(Router)

export default new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/docs/login',
      name: 'Login',
      meta: {
        is_public: true,
      },
      component: DocsLogin
    },
    {
      path: '/',
      redirect: 'welcome',
      name: 'Documents',
      component: TheDocsContainer,
      children: [
        {
          path: 'docs/welcome',
          name: 'Bienvenido',
          component: DocsHome
        },
      ]
    },
    {
      path: '/login',
      name: 'Login',
      meta: {
        is_public: true,
      },
      component: Login
    },
    {
      path: '/report-validation/:code',
      name: 'Validar Reporte',
      meta: {
        is_public: true,
      },
      component: ValidationTicket
    },
    {
      path: '/reporte/:code',
      name: 'Reporte',
      meta: {
        is_public: true,
      },
      component: PublicTicket
    },
    {
      path: '/reporte/:code/:phone',
      name: 'Reporte',
      meta: {
        is_public: true,
      },
      component: PublicTicket
    },
    {
      path: '/',
      redirect: 'welcome',
      name: 'Sorteos',
      component: TheContainer,
      children: [
        {
          path: '/welcome',
          name: 'Bienvenido',
          component: Welcome
        },
        {
          path: '/account',
          name: 'Mi Cuenta',
          component: MyAccount
        },
        {
          path: '/notifications',
          name: 'Bienvenido',
          component: MyNotifications
        },
        {
          path: '/bot-map',
          name: 'Mapa de Tickets',
          component: BotMap
        },
        {
          path: '/map',
          name: 'Mapa',
          component: Map
        },
        {
          path: '/demo',
          name: 'Mapa Demo',
          component: Demo
        },
        {
          path: '/roads',
          name: 'Vialidades',
          component: Roads
        },
        {
          path: '/users',
          name: 'Usuarios',
          component: Users
        },
        {
          path: '/roles',
          name: 'Roles',
          component: Roles
        },
        {
          path: '/collaborators',
          name: 'Colaboradores',
          component: Collaborators
        },
        {
          path: '/councils',
          name: 'Miembros de Cabildo',
          component: Councils
        },
        {
          path: '/directions',
          name: 'Direcciones',
          component: Directions
        },
        {
          path: '/supports-users',
          name: 'Usuarios de Bitacora',
          component: BinnacleUsers
        },
        {
          path: '/citizens',
          name: 'Ciudadanos',
          component: Citizens
        },
        {
          path: '/supports',
          name: 'Eventos de Bitacora',
          component: BinnacleEvents
        },
        {
          path: '/:code-supports',
          name: 'Eventos de Bitacora',
          component: BinnacleEvents
        },
        {
          path: '/supports-map',
          name: 'Mapa de Eventos',
          component: BinnacleMap
        },
        {
          path: '/:code-tickets',
          name: 'Reportes',
          component: Tickets
        },
        {
          path: '/tickets-map',
          name: 'Mapa de Reportes',
          component: TicketsMap
        },
        {
          path: '/tickets/:code/view',
          name: 'Reportes',
          component: Ticket
        },
        {
          path: '/capture',
          name: 'Captura de Información',
          component: Capture
        },
      ]
    },
    // {
    //   path: '/',
    //   name: 'Welcome',
    //   component: {        
    //     render (c) { return c('router-view') },
    //   },
    //   meta: {
    //     is_public: true,
    //   },
    //   children: [
    //     {
    //       path: '/',
    //       name: 'Landing Page',
    //       component: Landing,
    //       meta: {
    //         is_public: true,
    //       },
    //     },
    //     {
    //       path: '/boletos',
    //       name: 'Página de Boletos',
    //       component: Tickets,
    //       meta: {
    //         is_public: true,
    //       },
    //     },
    //     {
    //       path: '/pagos',
    //       name: 'Página de Pagos',
    //       component: Payments,
    //       meta: {
    //         is_public: true,
    //       },
    //     },
    //     {
    //       path: '/aviso-de-privacidad',
    //       name: 'Aviso de Privacidad',
    //       component: LandingTerms,
    //       meta: {
    //         is_public: true,
    //       },
    //     },
    //     {
    //       path: '/:code',
    //       name: 'Landing Page',
    //       component: Landing,
    //       meta: {
    //         is_public: true,
    //       },
    //     },
    //     {
    //       path: '/:code/pagos',
    //       name: 'Página de Pagos',
    //       component: Payments,
    //       meta: {
    //         is_public: true,
    //       },
    //     },
    //     {
    //       path: '/:code/boletos',
    //       name: 'Página de Boletos',
    //       component: Tickets,
    //       meta: {
    //         is_public: true,
    //       },
    //     },
    //     {
    //       path: '/:code/aviso-de-privacidad',
    //       name: 'Aviso de Privacidad',
    //       component: LandingTerms,
    //       meta: {
    //         is_public: true,
    //       },
    //     },
    //   ]
    // },
    {
      path: '/privacy',
      name: 'Política de Privacidad',
      meta: {
        is_public: true,
      },
      component: Privacy
    },
    {
      path: '/signup',
      name: 'Registra tu Cuenta',
      meta: {
        is_public: true,
      },
      component: NewAccountForm
    },
    {
      path: '/account/active/:id/:code',
      name: 'Activa tu Cuenta',
      meta: {
        is_public: true,
      },
      component: ActivationForm
    },
    {
      path: '/forget',
      name: '¿Olvidaste tu Contraseña?',
      meta: {
        is_public: true,
      },
      component: ForgetForm
    },
    {
      path: '/account/recover-password/:id/:code',
      name: 'Recupera tu Contraseña',
      meta: {
        is_public: true,
      },
      component: RecoveryForm
    },
    {
      path: '/link-login/:phone/:password',
      name: 'Recupera tu Contraseña',
      meta: {
        is_public: true,
      },
      component: Login
    }
  ]
})