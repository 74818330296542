<template>
  <div id="Login" class="login" :class="{ 'c-dark-theme': $store.state.darkMode }" style="background-color: rgb(246, 246, 246) !important;">
    <CContainer fluid class="fullheight" style="padding: 5px"> 
      <CRow class="fullheight" style="width: 100%; margin: auto;">
        <CCol cols="12" md="12" style="width: 100%; margin-top: 50px !important; padding: 0 5px;">
          <CForm id="login_form" method="POST" enctype="multipart/form-data">
            <div class="login__container" style="margin: auto; background-color: #F6F6F6 !important; max-width: 600px; padding: 20px;">
              <div class="login__inner" style="max-width: 600px;">
                <div class="login__image" style="text-align: center;">
                  <img src="main_logo.png" style="width: 200px;" alt="Logo Oscuro">
                </div>
                <div style="margin: 0px -50px; text-align: center;" v-if="!enabled"><br />La cuenta <b>{{domain}}</b> no ha sido configurada.</div>
                <div class="login__header pt-5" v-if="enabled">
                    <h4>Acceso con e.firma</h4>
                </div>
                <div class="login__subscribe d-flex justify-space-between align-center pb-8" v-if="enabled">
                  Plataforma de Documentación Municipal.
                  <br /><br />
                </div>
                <div class="login__form" v-if="enabled">
                  <CRow>
                    <CCol sm="12">
                      <CInput
                        name="cer_file"
                        id="cer_file"
                        type="file"
                        :value.sync="cer_file"
                        label="Certificado (.cer):"
                        placeholder=""
                        @change="loadCertificate"
                      />
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol sm="12">
                      <CInput
                        name="key_file"
                        id="key_file"
                        type="file"
                        :value.sync="key_file"
                        v-model="key_file"
                        label="Clave privada (.key):"
                        placeholder=""
                      />
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol sm="12">
                      <CInput
                        type="password"
                        v-model="key_password"
                        label="Contraseña de la clave privada:"
                        placeholder=""
                      />
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol sm="12">
                      <CInput
                        v-model="user_name"
                        label="Nombre del Empleado:"
                        placeholder=""
                        readonly
                      />
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol sm="12">
                      <CButton type="button" color="primary" style="width: 100%" @click="loginCertificate">INICIAR SESIÓN</CButton>
                    </CCol>
                  </CRow>
                </div>
                <div class="login__actions d-flex justify-space-between py-2">
                    <div class="login__remind" style="font-size: 12px;">
                    </div>
                    <div class="login__recovery-password" style="width: 100%">
                    </div>
                </div>
              </div>
            </div>
          </CForm>
        </CCol>
      </CRow>
    </CContainer>
    <loading :active="visible" :can-cancel="true"></loading>
    <CToaster placement="top-end">
      <template>
        <CToast v-for="(toast, key) in toasts" :color="toast.color" v-bind="toast" :key="'toast' + key" :show="true">
          {{toast.content}}
        </CToast>
      </template>
    </CToaster>
  </div>
</template>
<script>
import router from '../../../router/index'
import login from '../../../services/login';
import ws from '../../../services/general';
import Loading from 'vue-loading-overlay/dist/vue-loading.js';
import 'vue-loading-overlay/dist/vue-loading.css';
import store from '../../../store'
import axios from 'axios'

export default {
  name: 'Login',
  data: function () {
      return {
          key_password: '',
          image: 'img/login.jpg',
          domain: 'localhost',
          enabled: false,
          user_name: "",
          cer_file: null,
          key_file: null
      }
  },
  components: {
    Loading
  },
  mounted: async function() {
    this.loading();

    let response = await ws.getParameters(); 

    if(response.type == "success"){
      let parameters = response.data;

      this.domain = response.domain;
      this.enabled = response.enabled;

      let param1 = this.$findElementByName(parameters, "LOGIN_IMAGE");
      this.image = param1.value;

      let param2 = this.$findElementByName(parameters, "LOGIN_WELCOME");
      this.welcome = param2.value;

      let param3 = this.$findElementByName(parameters, "LOGIN_CTA");
      this.cta = param3.value;
    }
    this.loaded();
  },
  methods: {
    loadCertificate: async function(){
      this.loading();

      const form = document.getElementById("login_form");
      let formData = new FormData(form);

      let response = await ws.loadCertificate(formData);

      if(response.type == "success"){
        let data = response.data;

        this.user_name = data.name;
      }
      else{
        this.showToast("error", response.message);
      }

      this.loaded();
    },
    loginCertificate: async function(){
      this.loading();

      const form = document.getElementById("login_form");
      let formData = new FormData(form);

      formData.append("password", this.key_password);

      let response = await ws.loginCertificate(formData);

      if(response.type == "success"){
        let data = response.data;

        localStorage.setItem("latitude", data.account.latitude);
        localStorage.setItem("longitude", data.account.longitude);
        localStorage.setItem("domain", data.account.domain);
        localStorage.setItem("account",JSON.stringify(data.account));
        localStorage.setItem("employee",JSON.stringify(data.employee));
        localStorage.setItem("token", data.employee.api_token);

        axios.defaults.headers.common['domain'] = data.account.domain;
        axios.defaults.headers.common['api-token'] = data.employee.api_token;

        this.loaded();

        window.location.href = "/#/docs/welcome"; return;
      }
      else{
        this.showToast("error", response.message);

        this.loaded();
      }
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  },
  computed: {      
    visible() {
      return store.state.isLoading;
    },
    toasts () {
      return store.state.toasts;
    },
  },  
}
</script>
<style scoped>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
</style>
