import axios from 'axios'

export default {
    login: (data) => axios.post('login', data),
    loginPartner: (api_token) => axios.post('login-partner', {api_token: api_token}),
    loginOwner: (api_token, account_id) => axios.post('login-owner', {api_token: api_token, account_id: account_id}),
    validate: (data) => axios.post('login/validate', data),
    validateCertificate: (data) => axios.post('login/validate-certificate', data),
    validatePhone: (data) => axios.post('login/validate-phone', data),
    validateRFC: (rfc) => axios.post('validate-rfc', {rfc: rfc}),
    validateEmail: (email) => axios.post('validate-email', {email: email}),
    getUser: (id) => axios.post('get-user', {id: id}),
    activateAccount: (data) => axios.post('account/activate', data),
    recoveryAccount: (data) => axios.post('account/send-recover-password-link', data),
    recoveryPassword: (data) => axios.post('account/recover-password', data),
    storeAccount: (data) => axios.post('account/store', data),
    storeOwnerAccount: (data) => axios.post('account/owner-store', data),
}