<template>
  <div>
    <CRow>      
        <CCol md="3">
          <CCard>
            <CCardHeader>
              <slot name="header">
                <div class="card-header-actions" style="text-align: right; padding-top: 2px;">
                  <CButton color="info" size="sm" @click="" style="margin-right: 5px;">Ver Concurso</CButton>
                </div>
                <h5 style="margin-bottom: 0px;">Documentos por Entregar</h5>
              </slot>
            </CCardHeader>
            <CCardBody>
              <CRow>      
                <CCol md="12">
                    Hola           
                </CCol>                   
              </CRow>
            </CCardBody>
          </CCard>        
        </CCol>       
        <CCol md="3">
          <CCard>
            <CCardHeader>
              <slot name="header">
                <div class="card-header-actions" style="text-align: right;">
                  <CButton color="info" size="sm" @click="" style="margin-right: 5px;">Ver Concurso</CButton>
                </div>
                <h5 style="margin-bottom: 0px;">Documentos por Firmar</h5>
              </slot>
            </CCardHeader>
            <CCardBody>
              <CRow>      
                <CCol md="12">
                    Hola           
                </CCol>                   
              </CRow>
            </CCardBody>
          </CCard>        
        </CCol>       
        <CCol md="3">
          <CCard>
            <CCardHeader>
              <slot name="header">
                <div class="card-header-actions" style="text-align: right; padding-top: 2px;">
                  <CButton color="info" size="sm" @click="" style="margin-right: 5px;">Ver Concurso</CButton>
                </div>
                <h5 style="margin-bottom: 0px;">Documentos Entregados</h5>
              </slot>
            </CCardHeader>
            <CCardBody>
              <CRow>      
                <CCol md="12">
                    Hola           
                </CCol>                   
              </CRow>
            </CCardBody>
          </CCard>        
        </CCol>       
        <CCol md="3">
          <CCard>
            <CCardHeader>
              <slot name="header">
                <div class="card-header-actions" style="text-align: right; padding-top: 2px;">
                  <CButton color="info" size="sm" @click="" style="margin-right: 5px;">Ver Concurso</CButton>
                </div>
                <h5 style="margin-bottom: 0px;">Documentos Firmados</h5>
              </slot>
            </CCardHeader>
            <CCardBody>
              <CRow>      
                <CCol md="12">
                    Hola           
                </CCol>                   
              </CRow>
            </CCardBody>
          </CCard>        
        </CCol>                   
    </CRow>
  </div>
</template>
<script>
import router from '../../router/index'
import login from '../../services/login';
import ws from '../../services/general';
import Loading from 'vue-loading-overlay/dist/vue-loading.js';
import 'vue-loading-overlay/dist/vue-loading.css';
import store from '../../store'
import axios from 'axios'

export default {
  name: 'Home',
  data: function () {
      return {
          key_password: '',
          image: 'img/login.jpg',
          domain: 'localhost',
          enabled: false,
          user_name: "",
          cer_file: null,
          key_file: null
      }
  },
  components: {
    Loading
  },
  mounted: async function() {
    this.loading();

    let response = await ws.getParameters(); 

    if(response.type == "success"){
      let parameters = response.data;

      this.domain = response.domain;
      this.enabled = response.enabled;

      let param1 = this.$findElementByName(parameters, "LOGIN_IMAGE");
      this.image = param1.value;

      let param2 = this.$findElementByName(parameters, "LOGIN_WELCOME");
      this.welcome = param2.value;

      let param3 = this.$findElementByName(parameters, "LOGIN_CTA");
      this.cta = param3.value;
    }

    this.loaded();
  },
  methods: {
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  },
  computed: {      
    visible() {
      return store.state.isLoading;
    },
    toasts () {
      return store.state.toasts;
    },
  },  
}
</script>
<style scoped>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
</style>
